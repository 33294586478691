import React from "react";
import Carousel, { Modal, ModalGateway } from 'react-images';

// These override the default react-images <View /> and <Footer /> components.
// See http://jossmac.github.io/react-images/#/components for more info on custom components.
import GalleryAlbumCustomView from "src/components/gallery-album-custom-view";
import GalleryAlbumCustomFooter from "src/components/gallery-album-custom-footer";


class GalleryAlbum extends React.Component {
  constructor(props) {
    super(props);
    this.getModalGateway = this.getModalGateway.bind(this);
    this.createGalleries = this.createGalleries.bind(this);
  }


  state = {
    modalIsOpen: false,
    selectedIndex: 0,
    galleries: [],
    galleryAlbumList: [],
    currentGallery: [],
    stateUpdated: false,
    currentlyOpen: false,
    backupGallery: []
  }



  componentDidMount() {
    /*
    Load galleries data and set it to state. Used fetch() with the REST API instead of GraphQL with allWordpressWpEnviraGallery because the latter doesn't return all the info we need to render the galleries. (The subpages outside of the Documents page get all the necessary info via gallery shortcodes added to the subpages in WP. The shortcode provides more (and different) info than either allWordpressWpEnviraGallery or the REST API.

    An alternative approach to fetch() here in Documents might be to add the shortcode for each gallery to the Documents > Photos & Videos page in WP, then manipulate it with ReactHtmlParser (similar to subpage-article.js). You'd also have to modify other gallery-related functions below. This approach would require more work by a content administrator (i.e., adding each shortcode in two places) but could put galleries in Documents on par with galleries in subpages elsewhere on the site.
    */

    // IMPORTANT: The following fetch() is imperfect. It is currently limited to the first 100 galleries because WP API doesn't return more than 100 at a time. Will need to change if POLB has more than 100 galleries.
    fetch("https://thehelm.polb.com/wp-json/wp/v2/envira-gallery?status=publish&per_page=100")
      .then(response => response.json())
      .then(json => {
        // console.log("Response received");
        let galleryDataArray = ["1890", "1900", "1910", "1920", "1930", "1940", "1950", "1960", "1970", "1980", "1990", "2000", "2010"];
        let specificAlbumInfo = [];
        this.setState({
          galleries: this.createGalleries(json)
        }, function () {
          if (this.state.galleries.length > 0) {
            // Update the total that appears in parentheses after "Photos & Videos" in subnav
            // this.props.setDocumentsGalleriesTotal(this.state.galleries.length);

            // Find any and all Enviragallery Albums by matching title with galleryDataArray (i.e. if title of gallery is one of the years add it to specificAlbumInfo array)
            for (let index = 0; index < this.state.galleries.length; index++) {
              for (let decadeIndex = 0; decadeIndex < galleryDataArray.length; decadeIndex++) {
                if (this.state.galleries[index].title === galleryDataArray[decadeIndex]) {
                  // console.log("Found album");
                  specificAlbumInfo.push(this.state.galleries[index]);
                }
              }
            }
            this.setState({
              galleryAlbumList: specificAlbumInfo.reverse(),
              backupGallery: specificAlbumInfo[12]
            })
          }
        })
      })
  }


  /**
   * Create array that contains an object for each gallery on the site
   */
  createGalleries(json) {
    const galleries = json.map(data => {
      const items = data.gallery_data.gallery;

      const gallery = {
        title: data.title.rendered,
        galleryInfo: createGalleryInfo(items)
      }

      return gallery;
    });

    return galleries;


    /**
     * Create galleryInfo object for a particular gallery
     */
    function createGalleryInfo(items) {
      /*
        There are two keys that contain an img URL: link and src.
        Sometimes they are identical. Other times, src has dimensions tacked onto the end.
        If it's a Vimeo video, only src provides an img URL, because link is the URL to the video.

        ex (video):
          src     "https://thehelm.polb.com/wp-content/uploads/789210010_1280x720-1024x576.jpg"
          title   "Vimeo video"
          link    "https://vimeo.com/340781896"

        ex 1 (img):
          src     "https://thehelm.polb.com/wp-content/uploads/2018-green-flag-awards_005-1024x851.jpg"
          title   "SM Line Green Flag Award"
          link    "https://thehelm.polb.com/wp-content/uploads/2018-green-flag-awards_005.jpg"

        The only way to know if it's a video is to look for "vimeo.com" in the link value. A separate video field does not come back from the REST API like it does in the data-* attribute of the HTML that results from the shortcode when placing a gallery on pages.
      */
      const galleryInfo = items.map(item => {
        const isVideo = item.link.indexOf('vimeo.com') > -1 ? true : false;
        return (
          {
            title: item.title,
            full: !isVideo ? item.link : item.src,
            thumbnail: getThumbnailImg(item, isVideo),
            video: isVideo ? getVideoEmbedCode(item.link) : null,
            width: item.width,
            height: item.height,
            caption: item.caption
          }
        )
      })

      return galleryInfo;
    }

    /**
     * Construct thumbnail URL and return it
     * @param item      All data for a particular gallery item
     * @param isVideo   Boolean
     */
    function getThumbnailImg(item, isVideo) {
      let img;

      // ex: https://thehelm.polb.com/wp-content/uploads/strategicplan2019cover
      let imgMinusExtension;

      // .jpg, .png, etc.
      let imgFileExtension = item.src.substring(item.src.lastIndexOf('.'));

      if (!isVideo) {
        img = item.link;
        imgMinusExtension = img.substring(0, img.lastIndexOf('.'));
      } else {
        const src = item.src;
        const wxhIndex = src.indexOf(`-${item.width}x${item.height}.`);

        if (wxhIndex > -1) {
          imgMinusExtension = src.substring(0, wxhIndex);
        } else {
          imgMinusExtension = src.substring(0, src.lastIndexOf('.'));
        }
      }

      return `${imgMinusExtension}-150x150${imgFileExtension}`;
    }

    /**
     * Return Vimeo embed code
     * @param linkUrl   Formatted like https://vimeo.com/340781896
     */
    function getVideoEmbedCode(linkUrl) {
      let video = {};
      const vimeoId = linkUrl.substring(linkUrl.indexOf('.com/') + 5);

      video.embed_url = `//player.vimeo.com/video/${vimeoId}?autoplay=0&badge=0&byline=0&portrait=0&title=0&api=1&wmode=transparent&fullscreen=1`;

      return video;
    }
  }


  toggleModal = (selectedIndex, keepModalOpen, modalCurrentlyOpen) => {
    // console.log(this);
    if (this.state.currentGallery && this.state.currentGallery.length > 0) {
      this.setState(state => ({
        modalIsOpen: keepModalOpen || !state.modalIsOpen,
        selectedIndex,
        currentlyOpen: modalCurrentlyOpen ? modalCurrentlyOpen : false
      }));
    } else {
      this.setState({ currentGallery: modalCurrentlyOpen ? this.state.currentGallery : this.state.galleryAlbumList[selectedIndex] }, () => {
        this.setState(state => ({
          modalIsOpen: keepModalOpen || !state.modalIsOpen,
          selectedIndex,
          currentlyOpen: modalCurrentlyOpen ? modalCurrentlyOpen : false
        }));
      })
    }
    // this.setState({ currentGallery: modalCurrentlyOpen ? this.state.currentGallery : this.state.galleryAlbumList[selectedIndex] }, () => {
    //   this.setState(state => ({
    //     modalIsOpen: keepModalOpen || !state.modalIsOpen,
    //     selectedIndex,
    //     currentlyOpen: modalCurrentlyOpen ? modalCurrentlyOpen : false
    //   }));
    // })
  }


  /**
   * @return component react-images uses to render gallery in modal
   */
  getModalGateway(modalIsOpen, selectedIndex, currentAlbum) {
    // console.log(this.state.currentGallery);
    // console.log(selectedIndex);
    let startingIndex = this.state.currentlyOpen ? selectedIndex : 0;
    let specificAlbum = this.state.currentGallery ? this.state.currentGallery.galleryInfo : this.state.backupGallery.galleryInfo
    return (
      <ModalGateway>
        {modalIsOpen ? (
          <Modal onClose={this.toggleModal} allowFullscreen={false}>
            <Carousel
              components={{ Footer: GalleryAlbumCustomFooter, View: GalleryAlbumCustomView }}
              currentIndex={startingIndex}
              frameProps={{ accessibility: true }}
              hideControlsWhenIdle={false}
              views={specificAlbum}
              toggleModal={this.toggleModal}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    )
  }


  render() {
    const props = this.props;
    const data = !props.isInDocumentsPage ? JSON.parse(props.galleryInfo) : props.galleryInfo;
    const dataArray = Object.keys(data).map(i => data[i]);
    const { selectedIndex, modalIsOpen } = this.state;
    let currentAlbum = this.state.currentGallery;


    const albums = dataArray.map(album => {
      const albumInfo = {
        caption: `The ${album.title}'s`,
        source: album.cover_image_url
      }

      return albumInfo;
    });


    // for (let index = 0; index < this.state.galleries.length; index++) {
    //   for (let decadeIndex = 0; decadeIndex < galleryDataArray.length; decadeIndex++) {
    //     if (this.state.galleries[index].title === galleryDataArray[decadeIndex]) {
    //       specificAlbumInfo.push(this.state.galleries[index]);
    //     }
    //   }
    // }


    // if (!this.state.stateUpdated) {
    //   this.setState({ galleryAlbumList: specificAlbumInfo.reverse(), stateUpdated: true });
    // }



    return (
      <section className="gallery">
        <h4 className="visuallyhidden">Select an album thumbnail to display all images in gallery carousel</h4>
        <div className="gallery__preview">
          {albums.map(({ caption, source }, j) => (
            <div key={j} className="gallery__preview--album">
              <img
                alt={`Album: ${caption}`}
                src={source}
                onClick={() => this.toggleModal(j)}
                key={source}
                className="gallery__thumb"
              />
            </div>
          ))}
        </div>

        {this.getModalGateway(modalIsOpen, selectedIndex, currentAlbum)}
      </section>
    )
  }
}

export default GalleryAlbum;