import React from "react";
import Img from "gatsby-image";
import Helmet from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import queryString from 'query-string'
import Layout from "src/components/layout";
import SEO from "src/components/seo";
import Breadcrumb from "src/components/breadcrumb";
import NewsQuilt from "src/components/news-quilt.js";
import Gallery from "src/components/gallery/gallery";
import SingleFileDownload from "src/components/table-files-single";
import SocialMediaPanel from "src/components/social-media-panel";
import ReactIframeResizer from "react-iframe-resizer-super";
import 'babel-polyfill';
import AccordionButton from "src/components/accordion-button.js";
import DateSelection from "src/components/date-picker";
import DynamicStats from "src/components/dynamic-content-stats";
import TableFiles from "src/components/table-files";
import VisualizerIframe from "src/components/visualizer-iframe";
import TitleReplace from "src/components/subpage-title-adjustment";
import ExecutiveImageLink from "src/components/executive-image-link";
import ExecutiveNameLink from "src/components/executive-name-link";
import GalleryAlbum from "src/components/gallery-album";

class PostPreview extends React.Component {

  constructor(props) {
    super(props);
    this.replaceWithComponents = this.replaceWithComponents.bind(this);
  }

  state = {
    token: [],
    pageData: [],
    newsQuiltInfo: []

  }


  componentDidMount() {
    //Parse the query string with ID
    const value = queryString.parse(this.props.location.search);
    let wordpress_id;
    if (value) {
      wordpress_id = value.id;
    }
    // POST request 
    let requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ title: 'React POST Request Example' })
    };

    //First call to request the JWT token
    const firstCall = async () => {
      const response = await fetch('https://thehelm.polb.com/wp-json/jwt-auth/v1/token?username=user&password=nEip%PrA5Om4eTahrfm1lRtJ', requestOptions)
      const json = await response.json();

      if (json) {
        this.setState({ token: json.token }, () => secondCall());
      } else {
        console.log("token returned empty");
      }
    }

    //Fetch preview endpoint passing the token
    const secondCall = async () => {
      // GET request using fetch with set headers for bearer token
      requestOptions = {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + this.state.token
        },
        origin: "https://polb.com"
      };
      const response = await fetch(`https://thehelm.polb.com/wp-json/wp/v2/preview?id=${wordpress_id}`, requestOptions)
      const json = await response.json();
      if (json) {
        this.setState({ pageData: json });
        console.log(this.state.pageData);
      } else {
        console.log("Preview is empty");
      }
    }
    // Ensure the ID has been passed via the query string
    if (wordpress_id > 0) {
      firstCall();
    }

  }

  /**
 * Replace HTML from WP content with React components. Specifically, for Envira Gallery.
 */
  replaceWithComponents(node, index) {
    //if the content contains the table then render the table using ifraneresizer
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpdt-c") > -1)) {

      if (Array.isArray(node.children)) {
        let tableNode = node.children.find(current => {
          return current.name === "table" && current.attribs && current.attribs['data-wpdatatable_id'];
        });

        if (tableNode) {
          let tableID = tableNode.attribs['data-wpdatatable_id'];
          let tableUrl = 'https://thehelm.polb.com/stellar_custom_table/table' + tableID;

          const iframeResizerOptions = {
            checkOrigin: false,
            minHeight: 200,
            scrolling: false,
            heightCalculationMethod: 'documentElementOffset'
          };

          // for iframe sizer to work, we need a unique ID every time we generate this iframe
          // otherwise, on tab switches, the iframe wont be resized
          PostPreview.uniqueID++;

          return (
            <ReactIframeResizer
              key={'custom-table-' + PostPreview.uniqueID}
              id={'custom-table-' + PostPreview.uniqueID}
              src={tableUrl}
              iframeResizerOptions={iframeResizerOptions}
              iframeResizerUrl={false}
              className="stellar-custom-table documents-stellar-custom-table" />
          );
        }

      }

      return <h3>Could not load this table!</h3>;
    }
    // Replace Envira Gallery code with Gallery component
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-wrap") > -1)) {
      // const galleryInfo = node.children[0].attribs['data-gallery-images'];
      // return <Gallery key={index} galleryInfo={galleryInfo} previewMode="thumbs" />;

      let galleryConfig;

      for (let i = 0; i < node.children.length; i++) {
        if (node.children[i].attribs['data-gallery-config']) {
          galleryConfig = node.children[i];
          break;
        }
      }

      return <Gallery key={index} galleryConfig={galleryConfig.attribs['data-gallery-config']} galleryInfo={galleryConfig.attribs['data-gallery-images']} previewMode="thumbs" downloadable={galleryConfig} />;
    }

    // Remove extra Envira Gallery code
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("envira-gallery-theme-base") > -1)) {
      return null;
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("wpfd-single-file") > -1)) {
      return <SingleFileDownload key={index} data={node} />
    }

    //is this a form? then render the form on an iframe
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("gform_wrapper") > -1)) {

      let formId = node.attribs.id.split("_").slice(-1);
      let formUrl = 'https://thehelm.polb.com/gfembed/?f=' + formId;

      const iframeResizerOptions = {
        checkOrigin: false,
        minHeight: 500,
        scrolling: false,
        heightCalculationMethod: 'documentElementOffset'
      };

      PostPreview.uniqueID++;

      return (
        <ReactIframeResizer
          key={'gform-' + PostPreview.uniqueID}
          id={'gform-' + PostPreview.uniqueID}
          src={formUrl}
          iframeResizerOptions={iframeResizerOptions}
          iframeResizerUrl={false}
          className="stellar-gravity-form" />
      );
    }
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("stellar-collapse__title") > -1)) {
      return <AccordionButton yearTitle={node.children[0].data} />
    }

    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("ginput_container_date") > -1)) {
      return <DateSelection />
    }

    if (node.type === 'tag' && node.name === 'ul' && node.attribs.class && (node.attribs.class.indexOf("stats") > -1)) {
      return <DynamicStats statOne={node.children[0]} statTwo={node.children[1]} statThree={node.children[2]} />
    }

    if (node.type === 'tag' && node.name === 'img' && node.attribs.class && (node.attribs.class.indexOf("executive__img") > -1)) {
      return <ExecutiveImageLink execImg={node} execLink={node.next} />
    }

    if (node.type === 'tag' && node.name === 'strong' && node.children && node.children[0].attribs && node.parent && node.parent.attribs && (node.parent.attribs.class === "executive__description")) {
      return <ExecutiveNameLink execName={node} />
    }

    if (node.type === 'tag' && node.name === 'iframe' && node.attribs.class && (node.attribs.class.indexOf("board-meetings-iframe") > -1)) {

      const iframeResizerOptions = {
        checkOrigin: false,
        minHeight: 200,
        scrolling: false,
        heightCalculationMethod: 'documentElementOffset'
      };

      // for iframe sizer to work, we need a unique ID every time we generate this iframe
      // otherwise, on tab switches, the iframe wont be resized
      PostPreview.uniqueID++;

      return (
        <ReactIframeResizer
          key={'board-meetings-' + PostPreview.uniqueID}
          id={'board-meetings-' + PostPreview.uniqueID}
          src={node.attribs.src}
          iframeResizerOptions={iframeResizerOptions}
          iframeResizerUrl={false}
        />
      );

    }

    // Replace hidden files table div with TableFiles component that receives files table id as a prop
    if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("fileCategoryId") > -1)) {
      return <TableFiles key={index} tableData={this.props.files.find(obj => obj.filesId === node.children[0].data)} perPage="15" />;
    }


    // Eliminate strange break tag in Executives block
    if (node.type === 'tag' && node.name === 'h4' && node.attribs.class && (node.attribs.class.indexOf("executive__title") > -1)) {
      return <TitleReplace nodeInfo={node} />
    }

    // Replace HTML from Visualizer shortcode with VisualizerIframe component
    // ex: <div id="visualizer-1422-1140468016" class="visualizer-front  visualizer-front-1422"></div>
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.id && node.attribs.class && (node.attribs.class.indexOf("visualizer-front") > -1)) {
      const tableId = (node.attribs.id).split('-')[1]; // ex: turn visualizer-1422-1140468016 into 1422
      const isNumber = /^\d+$/.test(tableId);
      const cssClasses = node.attribs.class;
      const idx = cssClasses.indexOf('type-');
      let tableType = 0;

      // Determine the type of table so can style it accordingly
      if (idx > -1) {
        // tableType will equal a number, such as 1 or 2
        tableType = cssClasses.substring(idx + 5, cssClasses.indexOf(' '));
      }

      if (isNumber) {
        return <VisualizerIframe key={index}
          iframeSrc={`https://thehelm.polb.com/visualizer/?chart=${tableId}&type=${tableType}`} />;
      }
    }

    // Remove embedded <style> from Visualizer
    // ex: <style type="text/css" name="visualizer-custom-css" id="customcss-visualizer-1422">.visualizer-1422oddTableRow {background-color: #dd3333 !important;}</style>
    else if (node.type === 'style' && node.name === 'style' && node.attribs.name && (node.attribs.name.indexOf("visualizer-custom-css") > -1)) {
      return null;
    }

    // Remove edit link from Visualizer. Without doing this, an edit link may show above the table if edit permissions in Visualizer set to "All Users" for a particular table.
    else if (node.type === 'tag' && node.name === 'div' && node.attribs.class && (node.attribs.class.indexOf("visualizer-actions") > -1)) {
      return null;
    }
  }


  render() {
    const postData = this.state.pageData;
    console.log(postData);
    if (postData.id) {
      console.log("size " + postData.id);
      console.log(postData.title.rendered);
    }
    let monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    let normalDate = postData.id ? postData.date_gmt : "";
    let modifiedDate = "";
    let dateSplit = normalDate.length > 0 ? normalDate.split("T") : [];
    let dayMonthYearSplit = dateSplit.length > 0 ? dateSplit[0].split("-") : [];
    if (postData.id) {
      if (dayMonthYearSplit[2][0] === "0") {
        let dateDay = dayMonthYearSplit[2];
        let modifiedDateDay = dateDay.replace("0", "");
        let monthIndex = dayMonthYearSplit[1] - 1;
        modifiedDate = `${monthArray[monthIndex]} ${modifiedDateDay}, ${dayMonthYearSplit[0]}`;
      } else {
        let dateDay = dayMonthYearSplit[2];
        let monthIndex = dayMonthYearSplit[1] - 1;
        modifiedDate = `${monthArray[monthIndex]} ${dateDay}, ${dayMonthYearSplit[0]}`;
      }
    }

    const urlLink = postData.id > 0 && `https://polb.com/port-info/news-and-press${postData.link.split(".com")[1]}`;
    return (
      <React.Fragment>
        {postData.id ?
          <Layout hasHero={false}>
            <SEO title={postData.title.rendered} description={postData.excerpt.rendered} url={urlLink} />

            <Helmet>
              <body className="no-hero" />
            </Helmet>

            <Breadcrumb categories={postData.categories} pageTitle={postData.title.rendered} />

            {
              postData.categories && postData.categories[0] !== 274 &&
              <SocialMediaPanel data={postData} />
            }

            <article className="news gutter">
              <h1 className="news__heading" dangerouslySetInnerHTML={{ __html: postData.title.rendered }} />

              {
                postData.categories && postData.categories[0] !== 274 &&
                <div className="content content--date" dangerouslySetInnerHTML={{ __html: modifiedDate }} />
              }

              {/*{
              (postData.acf && postData.acf.cover_image) &&
              <Img fluid={postData.acf.cover_image.localFile.childImageSharp.fluid} />
            }*/}

              {
                (postData.acf && postData.acf.cover_image) &&
                <img src={postData.acf.cover_image} />
              }

              {
                (postData.content.rendered.indexOf('envira-gallery-wrap') > -1) ||
                  (postData.content.rendered.indexOf('wpfd-single-file') > -1) ||
                  (postData.content.rendered.indexOf("wpdt-c") > -1)
                  ?
                  <div className="content content--news">
                    {ReactHtmlParser(postData.content.rendered, { transform: this.replaceWithComponents })}
                  </div>
                  :
                  <div className="content content--news" dangerouslySetInnerHTML={{ __html: postData.content.rendered }} />
              }

            </article>
            {
              this.state.newsQuiltInfo.length > 0 &&
              <NewsQuilt newsQuiltData={this.state.newsQuiltInfo} quiltStyle="pattern-01" />
            }
          </Layout>
          :
          <div>{postData}</div>
        }
      </React.Fragment>
    );
  }
}

export default PostPreview;